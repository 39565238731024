.galleryMain {
  width: 100%;
  height: 500px;
  background-color: black;

  margin-top: 5px;
  margin-left: auto;
  margin-right: auto;
}

.galleryInner {
  height: 100%;
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
}

.galleryInner .left {
  flex: 5%;
  height: 100%;
  background-color: rgb(0, 0, 0, 0.5);
  display: grid;
  place-items: center;
  color: whitesmoke;
  cursor: pointer;
}

.galleryInner .center {
  flex: 90%;
  height: 100%;
}

.galleryInner .right {
  flex: 5%;
  height: 100%;
  background-color: rgb(0, 0, 0, 0.5);
  display: grid;
  place-items: center;
  color: whitesmoke;
  cursor: pointer;
}