.article-top {
  margin-top: 40px;
}

h6 {
  font-size: 21px;
}

a {
  color: black;
}

.sub-title {
  margin-bottom: 5px;
}