#container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

#app {
  flex: 1;
}

#logo {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

a {
  text-decoration: none;
}

.main-intro {
  color: gray;
  background-color: #FFC93C;
  margin-bottom: 10px;
  margin-top: 10px;
  border-radius: 5px;
  height: 50px;
  text-align: center;
  padding: 25px;
}

.main-intro-head {
  color: whitesmoke;
}

.main-intro-logo {
  text-align: center;
}

.main-intro-p {
  font-size: larger;
}

.main-cards::after {
  content: '';
  margin-top: 100vh solid;
  margin-right: 100vh solid transparent;

}

#footer {
  color: whitesmoke;
  background-color: #0081C9;
  text-align: center;
  clear: both;
  margin-top: 15px;
}

#copyright {
  margin-bottom: 5px;
}

#footer-links {
  margin-bottom: 10px;
}

#privacy-policy-link {
  color: whitesmoke;
  margin-top: 5px;
  margin-right: 10px;
}